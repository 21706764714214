import React from 'react';
import { Layout } from '../components/layout';

export default function Services() {
    const metaProps = {
        title: 'Services',
    };

    const services = [
        {
            title: 'Koloryzacja',
            items: [
                'Jednolita — klasyczna koloryzacja przy użyciu farb, dzięki której osiągniesz jednolitą barwę włosów, dla odzwierciedlenia Twojego stylu i charakteru.',
                'Odrostu — pokrywamy białe włosy lub odświeżymy wcześniejszy kolor na odroście, wkomponowując go bądź nadając wyrazistości.',
                'Odrostu blond — koloryzacja przy użyciu rozjaśniacza, dzięki jemu rozjaśnimy włosy do poziomu, po rozjaśnieniu całą długość tonujemy farbą.',
                'Airtouch — technika refleksów Air Touch pozwala na stworzenie lekkich i obłędnych przetopień kolorów. Aby osiągnąć najlepszy efekt, nasi styliści wykorzystają do tego gorące powietrze suszarki i technikę przeczesywania drobnych pasm włosów.',
                'Shatush / handtouch',
                'Flamboyage / ombre / sombre / Baleyage — to bardziej subtelna wersja ombre, twoje włosy staną się jaśniejsze w okolicach twarzy oraz na końcach, a przejścia kolorystyczne między odcieniami będzie mniej kontrastowe, nadając efekt naturalności i lekkości.',
                'Pasemka — pasemka polega na nakładaniu farby o mniej kontrastujących odcieniach i wybieraniu cieńszych pasemek do koloryzacji. Osiągniesz w ten sposób naturalny wygląd, a Twoje włosy nabiorą głębi kolorów i będą sprawiać wrażenie jakby były muśnięte słońcem.',
            ],
        },
        {
            title: 'Przedłużanie rzęs / laminowanie',
            items: [
                'Przedłużanie 1:1',
                'Uzupełnienie 1:1',
                'Przedłużanie 2D ',
                'Uzupełnienie 2D ',
                'Przedłużanie 3D',
                'Uzupełnienie 3D',
                'Przedłużanie 4D',
                'Uzupełnienie 4D',
                'Przedłużanie 5-6D',
                'Uzupełnienie 5-6D',
                'Przedłużanie 6-8D',
                'Uzupełnienie 6-8D',
                'Przedłużanie Mega Volume',
                'Uzupełnienie Mega Volume',
                'Koloryzacja rzęs',
                'Zdjęcie rzęs',
                'Stylizacja Kim Kardashian',
                'Kolorowe rzęsy',
                'Laminowanie rzęs',
            ],
        },
        {
            title: 'Strzyżenie / Modelowanie',
            items: [
                'Włosy krotkie / średnie / długie — Dopasowujemy cięcie i fryzurę do kształtu twarzy oraz stylu życia. Zabieg poprzedzony jest myciem głowy i zakończony stylizacją.',
                'Modelowanie włosów — Usługa mycia i modelowania na szczotce. Twoje włosy osiągną odpowiednią objętość już od samej nasady.',
                'Strzyżenie męskie',
                'Strzyżenie brody',
            ],
        },
        {
            title: 'Brwi',
            items: [
                'Henna brwi + geometria',
                'Henna pudrowa brwi + geometria',
                'Henna brwi i rzęs',
                'Laminowanie brwi',
                'Laminowanie + farbowanie brwi',
                'Henna męska + regulacja brwi',
                'Męska regulacja brwi',
            ],
        },
        {
            title: 'Makijaż',
            items: [
                'Dzienny',
                'wieczorowy/okolicznościowy',
                'do sesji zdjeciowej',
                'ślubny próbny',
                'ślubny',
            ],
        },
        {
            title: 'Makijaż permanentny',
            items: [
                'Makijaż permanentny brwi',
                'Makijaż permanentny brwi (korekta)',
                'Makijaż permanentny usta',
                'Makijaż permanentny usta (korekta)',
                'Makijaz peramanentny oczu i powiek',
            ],
        },
        {
            title: 'Manicure / Pedicure',
            items: [
                'Hybrydowy jeden kolor',
                'French hybrydowy',
                'Ombre',
                'Przedłużanie żelowe',
                'Uzupełnienie żelowe',
                'Wzmocnienie plyty paznokcia',
                'Manicure klasyczny',
                'Naprawa paznokcia',
                'Stylizacja paznokcia',
                'Przedluzanie jednego paznokcia',
                'Zdjęcie hybrydy',
                'Pedicure męski',
                'Pedicure hybrydowy',
                'Pedicure bez malowania',
                'Manicure męski',
            ],
        },
        {
            title: 'depilacja',
            items: [
                'leczenie wrastających włosów',
                'Bikini głębokie ',
                'Bikini klasyczne ',
                'Pachy ',
                'Nogi calość',
                'Goleń/biodra ',
                'Ręcę w całości',
                'Brzuch ',
                'Tylek ',
                'Plecy ',
                'Twarz 1 strefa ',
                'Twarz 2 strefy i więcej ',
                'Dolna część pleców ',
                'Linia brzucha ',
            ],
        },
        {
            title: 'masaż',
            items: [
                'Karnet ',
                'Klasyczny pleców',
                'Klasyczny całego ciała ',
                'Kończyny górnej',
                'Kończyny dolnej ',
                'Karku ',
                'Antycellulitowy ',
                'Gua-sha/twarzy ',
                'Aroma-relax',
                'Tejping ',
                'Sportowy',
                'Segmentarny',
            ],
        },
        {
            title: 'Zabiegi pielęgnacyjne',
            items: [
                'Olaplex',
                'Milkshake ',
                'keratynowe prostowanie — Dzięki silnie odżywiającym właściwością i wnikaniu w strukturę włosa, uzyskamy dla Ciebie olśniewający wygląd prostych i jedwabistych włosów. Proces polega na wnikaniu pod wpływem temperatury keratyny – naturalnej proteiny znajdującej się we włosach',
                'Fibre clinix',
                'Bonding system blond me',
            ],
        },
    ];

    return (
        <Layout metaProps={metaProps}>
            <section className="section-30 section-sm-top-35 section-xl-top-120 bg-image bg-image-4">
                <div className="shell shell-fluid">
                    <div className="range range-condensed">
                        <div className="cell-xs-12 cell-xl-preffix-1 cell-xl-11">
                            <p className="h6 text-italic font-serif text-regular">Dla naszych klientów </p>
                            <p className="h1">Usługi</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-60">
                <div className="shell shell-fluid">
                    <div className="range range-condensed">
                        {services.map(({ title, items }) => (
                            <div className="cell-sm-6 cell-xl-preffix-1 cell-xl-5" style={{paddingBottom: '30px'}}>
                                <div className="inset-sm-right-40 inset-xl-right-0">
                                    <h4 style={{textTransform: 'capitalize'}}>{title}</h4>
                                    <hr />
                                    <ul className="list-marked list-half-baked" >
                                        {items.map(item => (<li>{item}</li>))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    );
}